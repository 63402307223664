import { useState, ReactNode } from 'react';
import { User } from '@auth0/auth0-react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Permission, ProgressStatus, Seller, Transaction } from '../../interfaces';
import { useGlobalStore } from "../../store";
import { EditCampaign } from '../buyer/EditCampaign';
import { RequestedTools } from './RequestedTools';
import { AvailableTools } from './AvailableTools';
import { hasPermission } from "../../utils/permissions";
import React from 'react';
import _ from 'lodash';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

interface ToolsTabPanelProps {
  transactions?: Transaction[]
  sellers?: Seller[]
  user?: User
  refetchPage?: Function
}

const isTransaction = (item: Transaction | undefined): item is Transaction => {
  return !!item
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

export const ToolsTabPanel = (props: ToolsTabPanelProps) => {
  const [value, setValue] = useState(0);
  const buyerPersons = useGlobalStore((state) => state.buyerPersons);
  const buyerWithDetails = useGlobalStore((state) => state.buyerWithDetails);
  const theme = useTheme();
  
  const transactions = _.map(
    _.groupBy(props.transactions, 'process.id'),
    g => _.maxBy(g, "id")
  ).filter(isTransaction);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const requestedGoodIds = transactions.reduce<number[]>((array, transaction) => {
    array.push(transaction.process.bundle.goods[0].id)
    return array;
  }, []);

  const canceledGoodIds: number[] = transactions.reduce<number[]>((array, transaction) => {
    const { status, process } = transaction;
    if (status === ProgressStatus.Abandoned || status === ProgressStatus.Canceled ) {
      array.push(process.bundle.goods[0].id)
    }
    return array;
  }, []);

  if (!buyerWithDetails) {
    return null
  }

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: theme.palette.common.darkGreyText }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
        >
          <Tab label={<Typography variant="overline">Tools</Typography>}/>
          <Tab label={<Typography variant="overline">Details</Typography>}/>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <RequestedTools
          transactions={transactions}
          sellers={props.sellers}
          user={props.user}
          refetchPage={props.refetchPage} />
        {props.sellers?.map(seller => {
          return <AvailableTools
            changeTab={handleChange}
            key={seller.id}
            seller={seller}
            user={props.user}
            isShowSeller={props.sellers && props.sellers.length > 1}
            requestedGoodIds={requestedGoodIds}
            canceledGoodIds={canceledGoodIds}
            isRequestable={hasPermission(buyerPersons, buyerWithDetails.id, Permission.RequestTool)}
            refetchPage={props.refetchPage}
            hasRequestedTools={requestedGoodIds.length + canceledGoodIds.length > 0}
          />
        })}
      </TabPanel>
      <TabPanel value={value} index={1}><EditCampaign sellers={props.sellers} user={props.user}/></TabPanel>
    </React.Fragment>
  )
}