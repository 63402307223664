import { ThemeProvider } from '@mui/material/styles';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import ReactGA from "react-ga4";

import './App.css';
import Home from './Home';
import Landing from './Landing';
import LoginOrReturnHome from './header/LoginOrReturnHome';
import Header from './header/Header';
import Footer from './Footer';
import SellerPage from './seller/SellerPage';
import { UserSelfEditPage } from './user/UserSelfEditPage';
import { BuyerDetails } from './newCampaign';
import { theme } from '../utils/theme';
import Payment from './Payment';
import PageClose from './PageClose';
import { Dictionary } from 'lodash';


const GOOGLE_ANALYTICS_LOOKUP: Dictionary<string> = {
  "dev-demlaunch.staclabs.io": "G-1TLNBVL9NN",
  "demlaunch.staclabs.io": "G-TG9KQ078LQ",
  "testing-demlaunch.staclabs.io": "NotSetUpInGA",
  "demo-demlaunch.staclabs.io": "NotSetUpInGA",
  "localhost:3000": "NotSetUpInGA",
  "localhost:3001": "NotSetUpInGA",
  "localhost:3002": "NotSetUpInGA",
  "localhost:3003": "NotSetUpInGA",
  "localhost:3004": "NotSetUpInGA",
  "localhost:3005": "NotSetUpInGA"
} 
const domain = window.location.host;
if (domain in GOOGLE_ANALYTICS_LOOKUP ) {
  const googleAnalyticsKey = GOOGLE_ANALYTICS_LOOKUP[domain]
  ReactGA.initialize(googleAnalyticsKey);
}
else {
  console.error(`Domain ${domain} unknown for Google Analytics`)
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <div className='page-container'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/return" element={<LoginOrReturnHome />} />
          <Route path="/new" element={<Landing />} />
          <Route path="/:stateCode" element={<SellerPage />} />
          <Route path='/:stateCode/details' element={<BuyerDetails/>} />
          <Route path='/user' element={<UserSelfEditPage />} />
          <Route path='/payment/:transactionId' element={<Payment />} />
          <Route path='/pageClose' element={<PageClose />} />
          <Route path='*' element={<Navigate to="/" replace={true} />}/>
        </Routes>
      </div>
      <Footer/>
    </ThemeProvider>
  );
}

export default App;
